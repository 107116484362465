import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { SpinnerComponent } from './_components/spinner/spinner.component'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, SpinnerComponent],
})
export class AppComponent {
    constructor() {}
}
